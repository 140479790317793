import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiCast, FiLayers, FiUsers, FiMonitor, FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Community Building',
        description: 'Building a community of supporters around your blockchain project, by implementing a strong community moderation, engagement activities, and marketing campaigns. The goal is to create a network of engaged users who are passionate about your project and can help spread the word to others. As we operate within 16 different local regions, we are able to locally penetrate into any community within the globe. '
    },
    {
        icon: <FiLayers />,
        title: 'Guerilla Marketing',
        description: 'Using unconventional and often low-cost tactics to promote a product or service. Guerilla marketing involves creating organic conversation within forums and communities on various social media such as Telegram and Reddit.'
    },
    {
        icon: <FiUsers />,
        title: 'Influencer Marketing',
        description: 'Involves partnering with influential individuals or organizations in the blockchain industry to promote your project. Influencers can help you reach a wider audience and build trust with potential customers, and may include bloggers, social media influencers, or industry experts. '
    },
    {
        icon: <FiMonitor />,
        title: 'Press Release',
        description: 'At Marilyn PR, we offer organic publications with backlinks, solely at cryptocurrency PR outlets. Publications are then distributed to up to 400 websites. Our blockchain PR methods and tactics are based on close connections with some of the top editors, writers, and content producers in the world. Enabling us to obtain coverage in both prestigious newspapers and Tier-1 influencers’ channels around the globe.'
    },
    {
        icon: <FiCast />,
        title: 'Advisory and Consultancy',
        description: 'Developing a strong and consistent brand identity for your blockchain project. This may include things like logo design, brand messaging, and visual branding elements that help your project stand out in a crowded market.  '
    },
    {
        icon: <FiMonitor />,
        title: 'Brand Building',
        description: 'Developing a strong and consistent brand identity for your blockchain project. This may include things like logo design, brand messaging, and visual branding elements that help your project stand out in a crowded market. '
    },
    {
        icon: <FiMonitor />,
        title: 'Social Media Management',
        description: 'Managing your social media accounts and creating content that engages and builds your audience. We assist with content creation and social media advertising to help you reach a wider audience and build brand awareness.'
    },
]
class Service extends Component {
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Marketing' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Marketing'} />
                {/* End Breadcrump Area */}

                {/* Start Service Area */}
                <div className="service-area ptb--120 " style={{ backgroundColor: '#00010c' }}>
                    <div className="container" >
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2 style={{ color: 'white' }}>Services</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ServiceList.map((val, i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>

                                    <div className="service service__style--2">
                                        <div className="icon">
                                            {val.icon}
                                        </div>
                                        <div className="content">
                                            <h3 className="title" style={{ color: 'white' }}>{val.title}</h3>
                                            <p style={{ color: 'white' }}>{val.description}</p>
                                        </div>
                                    </div>

                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Service Area */}
                {/*                 <div className="service-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">ww
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Strategy</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="text-center" href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
                {/* End Service Area */}

                {/* Start Service Area */}
                {/*                 <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Creative Agency</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
                {/* End Service Area */}

                {/* Start Service Area */}
                {/*                 <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Development</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="text-center" href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
                {/* End Service Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default Service;