import React, { Component } from "react";

class About extends Component{
    render(){
        let title = 'About',
        description = 'Marilyn PR is a comprehensive blockchain incubator focusing on marketing, advisory, and community building for early-stage blockchain projects. Founded in 2021 at Dubai, we have assisted over 25 clients early on and ensured their success. Marilyn PR has a core team of 20 people, spread across the world who are hand-picked to deliver the best results within their expertise. As we operate within 16 different local regions, we are able to locally penetrate into anywhere within the globe. We manage early-stage brands and provide them with the required marketing strategies and connections to strive. Our aspiration comes from providing the neccessary tools for blockchain to reach 1 billion users.';
        return(
            <React.Fragment>
                <div className="about-wrapper" style={{ backgroundColor: '#101010' }}>
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-1.jpg" alt="About Images"/>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title mt-50" style={{marginTop: 50}}>{title}</h2>
                                        <p className="description">{description}</p>
                                    </div>
                                    <div className="row mt--30 mt_sm--10">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Who we are</h3>
                                                <p>We believe in founders in web3 and their motives to take blockchain to the next level. Hence, every area of support is provided by Marilyn PR to accelerate businesses, institutions, or governments adoption into Web3. </p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Why you should choose us</h3>
                                                <p>With a comprehensive team and know-how, our goal is to accelerate teams ability to take their products to the market. Our team of experts will work with you to develop a comprehensive blockchain marketing strategy tailored to your specific needs. We will analyze your target audience, competition, and industry trends to create a plan that maximizes your ROI.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;