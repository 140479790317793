import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { useCalendlyEventListener, InlineWidget } from "react-calendly";

const Result = () => {
    return (
        <p className="success-message">Your Message has been successfully sent. I will contact you soon.</p>
    )
}

function ContactForm({ props }) {
    const [result, showresult] = useState(false);
    const [eventScheduled, setEventScheduled] = useState(false);
    const [email, setEmail] = useState("");
    const [webSiteUrl, setWebSiteUrl] = useState("");
    const [message, setMessage] = useState("");
    const [stage, setStage] = useState([]);
    const [serviceData, setServiceData] = useState([]);
    const [budget, setBudget] = useState([]);
    const [category, setCategory] = useState([]);
    let isValueValid = (value) => {
        if (value === null || value === undefined || value === "") {
            return false;
        }
        return true;
    }

    const selectedDataService = (value, type) => {
        let service;
        switch (type) {
            case 1:
                service = dataOfService.find(p => p.id === value);
                if (!serviceData.includes(service.text)) {
                    serviceData.push(service.text);
                } else {
                    serviceData.pop(service.text);
                }
                return;
            case 2:
                service = dataOfCategory.find(p => p.id === value);
                if (!category.includes(service.text)) {
                    category.push(service.text);
                } else {
                    category.pop(service.text);
                }
                return;
            case 3:
                service = dataOfStage.find(p => p.id === value);
                if (!stage.includes(service.text)) {
                    stage.push(service.text);
                } else {
                    stage.pop(service.text);
                }
                return;
            case 4:
                service = dataOfBudget.find(p => p.id === value);
                if (!budget.includes(service.text)) {
                    budget.push(service.text);
                } else {
                    budget.pop(service.text);
                }
                return;
            default:
                return;
        }
        console.log(service);
    }
    const dataOfService = [
        {
            id: 1,
            text: 'Community Management'

        },
        {
            id: 2,
            text: 'Growth Hacking Campaigns'
        },
        {
            id: 3,
            text: 'Influencer & KOL Marketing'
        },
        {
            id: 4,
            text: 'Social Media Management'
        },
        {
            id: 5,
            text: 'Article and Social Media Content Writing'
        }, {
            id: 6,
            text: 'Press Release and Organic Publications'

        },
        {
            id: 7,
            text: 'Advisory and Consultancy'
        },
        {
            id: 8,
            text: 'Blockchain Software Development'
        },
        {
            id: 9,
            text: 'Other'
        },
    ];

    const dataOfCategory = [{
        id: 1,
        text: 'NFT'
    },
    {
        id: 2,
        text: 'DeFi'
    }, {
        id: 3,
        text: 'Metaverse'
    }, {
        id: 4,
        text: 'Launch Pad IDO/ICO/Token Sale'
    }, {
        id: 5, text: 'Exchange/DEX'
    }, {
        id: 6, text: 'Other'
    }
    ]
    const dataOfStage = [{
        id: 1,
        text: 'Private Sale is open'

    },
    {
        id: 2,
        text: 'Private Sale is closed'
    },
    {
        id: 3,
        text: 'Pre TGE'
    },
    {
        id: 4,
        text: 'Post TGE'
    },
    { id: 5, text: 'Platform is live' }
    ]
    const dataOfBudget = [{
        id: 1,
        text: '<$10,000'
    },
    {
        id: 2,
        text: '$10,000-$25,000'
    },
    { id: 3, text: '$25,000-$50,000' },
    { id: 4, text: '$50,000-$100,000' },
    { id: 5, text: '$100,000-$250,000' },
    { id: 6, text: '>$250,000' }

    ]
    useCalendlyEventListener({
        onProfilePageViewed: () => console.log("onProfilePageViewed"),
        onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
        onEventTypeViewed: () => console.log("onEventTypeViewed"),
        onEventScheduled: (e) => e.data.payload == null || e.data.payload == undefined || e.data.payload == "" ? setEventScheduled(false) : setEventScheduled(true),
    });

    const checkForm = () => {
        let emailOk = isValueValid(email);
        let webSiteUrlOk = isValueValid(webSiteUrl);
        let budgetOk = budget.length > 0 || budget !== null;
        let categoryOk = category.length > 0 || category !== null;
        let serviceOk = serviceData.length > 0 || serviceData !== null;
        let stageOk = stage.length > 0 || stage !== null;
        return emailOk && webSiteUrlOk && budgetOk && categoryOk && serviceOk && stageOk;
    }

    const template_params = {
        email: email,
        webSiteUrl,
        budget,
        stage,
        serviceData: serviceData,
        category,
        message
    }
    const sendEmail = () => {
        if (!checkForm()) return;
        console.log(template_params);
        emailjs
            .send(
                'service_4njvopj',
                'template_642jazu',
                template_params,
                '4bKuONDW6TixB1t0L'
            )
            .then((result) => {
                console.log(result)
                console.log(result.text);
            },
                (error) => {
                    console.log(error.text);
                }
            );
        showresult(true);
    };

    setTimeout(() => {
        showresult(false);
    }, 5000);

    return (
        <div>
            {!eventScheduled ?
                <div className="Calendly">
                    <InlineWidget url="https://calendly.com/marilynprcompany" />
                </div> : <>
                    <div className="" style={{ backgroundColor: 'transparent', padding: 20, borderWidth: 1, borderColor: 'white', borderRadius: 10 }}>
                        <div className="col-lg-12 col-md-12 col-sm-12 container text-center" >
                            <h6 style={{ color: 'white' }}>Email<span style={{ color: '#f9004d' }}>*</span></h6>
                            <input type="email" onChange={(e) => setEmail(e.target.value)} className="form-control" style={{ borderColor: 'white', color: 'white', backgroundColor: '#101010' }}></input>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 container text-center">
                            <h6 style={{ color: 'white' }}>Website URL<span style={{ color: '#f9004d' }}>*</span></h6>
                            <input type="text" onChange={(e) => setWebSiteUrl(e.target.value)} style={{ borderColor: 'white', color: 'white', backgroundColor: '#101010' }}></input>
                        </div>
                        <div className="container text-center">
                            <h6 style={{ color: 'white' }}>Which services are you interested in?<span style={{ color: '#f9004d' }}>*</span></h6>
                            <div className="d-lg-flex d-md-flex d-sm-flex" style={{ margin: 20 }}>
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    {dataOfService.map((item, index) => {
                                        if (item.id <= 5) {
                                            return (
                                                <div className="d-flex d-lg-flex d-md-flex d-sm-inline-block" style={{ alignItems: 'center', alignContent: 'flex-start', marginTop: 20, marginBottom: 20 }} key={item.id}>
                                                    <input className="col-lg-3 col-md-2 col-sm-3" onChange={() => selectedDataService(item.id, 1)} type="checkbox" style={{ height: 15, marginTop: '5%', accentColor: '#f9004d' }}></input>
                                                    <span className="col-lg-9 col-md-4 col-sm-3" style={{ fontSize: '1em', color: 'white' }}>{item.text}</span>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    {dataOfService.map((item, index) => {
                                        if (item.id > 5) {
                                            return (<div className="d-flex d-lg-flex d-md-flex d-sm-inline-block" style={{ alignItems: 'center', alignContent: 'flex-start', marginTop: 20, marginBottom: 20 }} key={item.id}>
                                                <input className="col-lg-3 col-md-2 col-sm-3" onChange={() => selectedDataService(item.id, 1)} type="checkbox" style={{ height: 15, marginTop: '5%', accentColor: '#f9004d' }}></input>
                                                <span className="col-lg-9 col-md-4 col-sm-3" style={{ fontSize: '1em', color: 'white' }}>{item.text}</span>
                                            </div>)
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="container text-center">
                            <h6 style={{ color: 'white' }}>Project Category Type?<span style={{ color: '#f9004d' }}>*</span></h6>
                            <div className="d-lg-flex d-md-flex d-sm-flex" style={{ margin: 20 }}>
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    {dataOfCategory.map((item, index) => {
                                        if (item.id <= 3) {
                                            return (<div className="d-flex d-lg-flex d-md-flex d-sm-inline-block" style={{ alignItems: 'center' }} key={item.id}>
                                                <input className="col-lg-3 col-md-2 col-sm-3" onChange={() => selectedDataService(item.id, 2)} type="checkbox" style={{ height: 15, marginTop: '5%', accentColor: '#f9004d' }}></input>
                                                <span className="col-lg-9 col-md-4 col-sm-3" style={{ fontSize: '1em', color: 'white' }}>{item.text}</span>
                                            </div>)
                                        }
                                    })}
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    {dataOfCategory.map((item, index) => {
                                        if (item.id > 3) {
                                            return (<div className="d-flex d-lg-flex d-md-flex d-sm-inline-block" style={{ alignItems: 'center' }} key={item.id}>
                                                <input className="col-lg-3 col-md-2 col-sm-4" onChange={() => selectedDataService(item.id, 2)} type="checkbox" style={{ height: 15, marginTop: '5%', accentColor: '#f9004d' }}></input>
                                                <span className="col-lg-6 col-md-6 col-sm-8" style={{ fontSize: '1em', color: 'white' }}>{item.text}</span>
                                            </div>)
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="container text-center">
                            <h6 style={{ color: 'white' }}>Product Stage?<span style={{ color: '#f9004d' }}>*</span></h6>
                            <div className="d-lg-flex d-md-flex d-sm-flex" style={{ margin: 20 }}>
                                <div className="col-lg-6 col-md-6 col-sm-2">
                                    {dataOfStage.map((item, index) => {
                                        if (item.id <= 3) {
                                            return (
                                                <div className="d-flex d-lg-flex d-md-flex d-sm-inline-block" style={{ alignItems: 'center' }} key={item.id}>
                                                    <input className="col-lg-3 col-md-2 col-sm-3" onChange={() => selectedDataService(item.id, 3)} type="checkbox" style={{ height: 15, marginTop: '5%', accentColor: '#f9004d' }}></input>
                                                    <span className="col-lg-9 col-md-4 col-sm-3" style={{ fontSize: '1em', color: 'white' }}>{item.text}</span>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-10">
                                    {dataOfStage.map((item, index) => {
                                        if (item.id > 3) {
                                            return (
                                                <div className="d-flex d-lg-flex d-md-flex d-sm-inline-block" style={{ alignItems: 'center' }} key={item.id}>
                                                    <div className="col-lg-3 col-md-2 col-sm-6">
                                                        <input onChange={() => selectedDataService(item.id, 3)} type="checkbox" style={{ height: 15, marginTop: '5%', accentColor: '#f9004d' }}></input>
                                                    </div>
                                                    <div className="col-lg-9 col-md-4 col-sm-6">
                                                        <span style={{ fontSize: '1em', color: 'white' }}>{item.text}</span>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="rn-form-group container text-center">
                            <h6 style={{ color: 'white', marginTop: '3%', marginBottom: '3%' }}>Message</h6>
                            <textarea
                                name="message"
                                placeholder="Your Message"
                                required
                                onChange={(e) => setMessage(e.target.value)}
                                style={{ backgroundColor: '#191919', borderColor: 'white', color: 'white' }}>
                            </textarea>
                        </div>
                        <div className="container text-center">
                            <h6 style={{ color: 'white' }}>Expected Marketing Budget Over the Next 6 months?<span style={{ color: '#f9004d' }}>*</span></h6>
                            <div className="d-lg-flex d-md-flex d-sm-flex" style={{ margin: 20 }}>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    {dataOfBudget.map((item, index) => {
                                        if (item.id <= 3) {
                                            return (<div className="d-flex d-lg-flex d-md-flex d-sm-inline-block" style={{ alignItems: 'center' }} key={item.id}>
                                                <input className="col-lg-3 col-md-2 col-sm-4" onChange={() => selectedDataService(item.id, 4)} type="checkbox" style={{ height: 15, marginTop: '5%', accentColor: '#f9004d' }}></input>
                                                <span className="col-lg-9 col-md-4 col-sm-8" style={{ fontSize: '1em', color: 'white' }}>{item.text}</span>
                                            </div>)
                                        }
                                    })}
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    {dataOfBudget.map((item, index) => {
                                        if (item.id > 3) {
                                            return (<div className="d-flex d-lg-flex d-md-flex d-sm-inline-block" style={{ alignItems: 'center' }} key={item.id}>
                                                <input className="col-lg-3 col-md-2 col-sm-4" type="checkbox" onChange={() => selectedDataService(item.id, 4)} style={{ height: 15, marginTop: '5%', accentColor: '#f9004d' }}></input>
                                                <span className="col-lg-9 col-md-4 col-sm-8" style={{ fontSize: '1em', color: 'white' }}>{item.text}</span>
                                            </div>)
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="rn-form-group text-center" style={{ margin: '5%' }}>
                            <button className="rn-button-style--2 btn-solid" onClick={sendEmail} type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Submit Now</button>
                        </div>

                        <div className="rn-form-group">
                            {result ? <Result /> : null}
                        </div>
                    </div ></>
            }</div >
    )
}
export default ContactForm;
