import React, { Component } from "react";

class Brand extends Component {
    render() {
        const { branstyle } = this.props;
        return (
            <React.Fragment>
                <ul className={`brand-list ${branstyle}`}>
                    <li>
                        <img className='rounded-circle' src="/assets/images/brand/Arbipad.jpg" alt="Logo Images" />
                    </li>
                    <li>
                        <img className='rounded-circle' src="/assets/images/brand/BSCPAD.png" alt="Logo Images" />
                    </li>
                    <li>
                        <img className='rounded-circle' src="/assets/images/brand/DaoMaker.jpg" alt="Logo Images" />
                    </li>
                    <li>
                        <img src="/assets/images/brand/PinkSale.png" alt="Logo Images" />
                    </li>
                    <li>
                        <img src="/assets/images/brand/Polkastarter.jpg" alt="Logo Images" />
                    </li>
                    <li>
                        <img src="/assets/images/brand/TitansVentures.jpg" alt="Logo Images" />
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default Brand;