import React, { Component } from "react";

const ServiceList = [
    {
        icon: "01",
        title: 'Community Building',
        description: 'Building a community of supporters around your blockchain project, by implementing a strong community moderation, engagement activities, and marketing campaigns. The goal is to create a network of engaged users who are passionate about your project and can help spread the word to others. As we operate within 16 different local regions, we are able to locally penetrate into any community within the globe. '
    },
    {
        icon: "02",
        title: 'Press Release',
        description: 'At Marilyn PR, we offer organic publications with backlinks, solely at cryptocurrency PR outlets. Publications are then distributed to up to 400 websites. Our blockchain PR methods and tactics are based on close connections with some of the top editors, writers, and content producers in the world. Enabling us to obtain coverage in both prestigious newspapers and Tier-1 influencers’ channels around the globe.'
    },
    {
        icon: "03",
        title: 'Influencer Marketing',
        description: 'Involves partnering with influential individuals or organizations in the blockchain industry to promote your project. Influencers can help you reach a wider audience and build trust with potential customers, and may include bloggers, social media influencers, or industry experts. '
    },
]

class ServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1">
                                <div className="icon">
                                    <img src={`/assets/images/icons/icon-${val.icon}.png`} alt="Digital Agency"/>
                                </div>
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceOne;