import React, { Component } from "react";
import ContactForm from "./ContactForm";

class ContactTwo extends Component {
    render() {
        return (
            <div className="contact-form--1" style={{ color: '#191919', backgroundColor: '#00010c' }}>
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <h2 className="title" style={{ color: 'white', textAlign: 'center' }}>Get In Touch</h2>
                            </div>
                            <div className="form-wrapper">
                                <ContactForm />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
export default ContactTwo;