import React, { Component } from "react";
import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";

const SocialShare = [
    { Social: <FaFacebookF />, link: 'https://www.facebook.com/' },
    { Social: <FaLinkedinIn />, link: 'https://www.linkedin.com/' },
    { Social: <FaInstagram />, link: 'https://www.instagram.com/' },
    { Social: <FaTwitter />, link: 'https://twitter.com/' },
]

class Footer extends Component {
    constructor(props) {
        super(props)
        this.state = { matches: window.matchMedia("(min-width: 768px)").matches };
    }

    componentDidMount() {
        const handler = e => this.setState({ matches: e.matches });
        window.matchMedia("(min-width: 768px)").addEventListener('change', handler);
    }
    render() {
        return (
            <React.Fragment>
                <footer className="footer-area" style={{ backgroundColor: '#00010c' }} >
                    <div className="footer-wrapper">
                        <div className="row align-items-end row--0">
                            <div className="col-lg-6">
                                <div className="footer-left">
                                    <div className="inner">
                                        <div className="d-flex">
                                            <div className="col-lg-8">
                                                <span style={{ color: ("#FFFFFF") }}>Get</span>
                                                <h2>In Touch</h2>
                                                <a className="rn-button-style--2" href="/contact">
                                                    <span>Contact Us</span>
                                                </a>
                                            </div>
                                            <div className="col-lg-4">
                                                <svg width={!this.state.matches ? "120" : "215"} height={!this.state.matches ? "120" : "215"} viewBox="0 0 215 238" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0 98.1819V196.363H4.38777H8.77553V106.715C8.77553 55.8251 9.21431 17.4778 9.76278 17.9919C10.5306 18.9171 25.0103 44.3106 47.4976 84.3028C51.4466 91.3966 57.6991 102.5 61.319 108.977C65.0486 115.454 71.0818 126.248 74.8114 132.828L81.5028 144.959H86.3293C90.6074 144.959 91.1558 144.754 90.6074 143.109C90.1686 142.184 74.7017 114.528 56.2731 81.7327C37.8445 48.937 19.9643 17.1694 16.5638 11.1037L10.4209 0.000501886H5.26532H0V98.1819Z" fill="white" />
                                                    <path d="M29.508 15.627C34.3345 24.2629 52.5437 56.7501 70.0948 87.9009L101.796 144.445L106.952 144.754C110.901 144.959 111.998 144.754 111.669 143.725C111.34 143.006 99.9316 122.444 86.2198 98.1816C72.6178 73.8162 54.6279 41.843 46.2912 27.0387L31.2631 0.103051L25.9977 0.00024322H20.7324L29.508 15.627Z" fill="white" />
                                                    <path d="M41.6826 0.308925C41.6826 0.617348 67.6801 47.1893 106.402 116.173C114.19 130.052 120.882 142.184 121.321 143.212C121.869 144.754 123.076 144.959 127.573 144.754L133.168 144.445L168.928 81.013L204.578 17.4778L204.908 106.921L205.127 196.363H210.063H214.999V98.1819V0.000501886H209.624H204.249L199.313 8.43074C196.68 13.1599 190.209 24.5716 185.053 33.9271C152.035 93.2471 128.67 134.165 127.793 133.959C127.134 133.856 110.571 104.967 90.8256 69.7041C71.0807 34.4411 54.1878 4.52404 53.3102 3.08473C51.9939 1.02858 50.6775 0.411733 46.7285 0.206117C43.9862 0.000501886 41.6826 0.10331 41.6826 0.308925Z" fill="white" />
                                                    <path d="M158.837 6.88767C143.041 34.8514 133.498 51.7118 122.857 70.3201C110.133 92.835 109.804 93.9658 113.862 98.695L115.947 101.06L127.026 81.6289C133.059 70.9369 145.893 48.2164 155.546 31.0475L173.097 -0.000450678H167.942H162.786L158.837 6.88767Z" fill="white" />
                                                    <path d="M181.874 3.08409C180.886 4.72902 175.841 13.6733 170.685 22.8232C165.529 32.0759 152.256 55.7217 141.068 75.5635L120.884 111.546L123.187 115.35C124.394 117.509 125.71 119.257 126.149 119.257C126.698 119.257 136.022 102.91 170.356 41.7398C189.333 7.91606 193.063 1.23355 193.063 0.616706C193.063 0.308283 190.978 -0.000140011 188.455 -0.000140011C184.397 -0.000140011 183.519 0.411091 181.874 3.08409Z" fill="white" />
                                                    <path d="M191.746 51.4044L187.577 58.4981V127.482V196.363H191.965H196.353V120.286C196.353 78.4428 196.243 44.2078 196.133 44.2078C195.914 44.2078 193.939 47.4977 191.746 51.4044Z" fill="white" />
                                                    <path d="M17.5508 121.314V196.363H22.487H27.4233V128.51V60.5542L23.3646 53.4605C21.061 49.5538 18.8671 46.264 18.4283 46.264C17.9896 46.264 17.5508 79.9849 17.5508 121.314Z" fill="white" />
                                                    <path d="M173.097 84.6113L168.929 92.1162V144.24V196.363H173.316H177.704V136.735C177.704 103.939 177.595 77.1063 177.485 77.1063C177.265 77.1063 175.291 80.499 173.097 84.6113Z" fill="white" />
                                                    <path d="M36.1982 137.249V196.363H40.9151H45.5222L44.9738 143.726L44.4253 90.9853L40.8054 84.5084C38.8309 81.013 37.0758 78.1344 36.7467 78.1344C36.4176 78.1344 36.1982 104.762 36.1982 137.249Z" fill="white" />
                                                    <path d="M154.998 116.995L150.501 124.912L150.611 160.38L150.83 195.849L155.547 196.157L160.154 196.466V152.773C160.154 128.716 160.044 108.976 159.935 108.976C159.715 108.976 157.521 112.575 154.998 116.995Z" fill="white" />
                                                    <path d="M54.8467 153.39V196.363H59.2344H63.6222V160.895C63.6222 122.136 63.9513 124.501 57.0406 113.809L54.8467 110.519V153.39Z" fill="white" />
                                                    <path d="M24.1328 229.673C24.1328 238.822 26.1073 240.262 26.5461 231.523L26.8752 225.663L29.5078 229.981C31.0435 232.448 32.4696 234.402 32.9083 234.402C33.2374 234.402 34.7731 232.243 36.1992 229.673L38.9415 225.046L39.5997 231.523L40.2579 238L40.3675 229.981C40.4772 225.663 40.2579 222.065 39.7094 222.065C39.2706 222.065 37.7349 224.121 36.1992 226.691C34.6634 229.261 33.1277 231.317 32.689 231.317C32.3599 231.317 30.8242 229.364 29.3981 227C25.3394 220.111 24.1328 220.728 24.1328 229.673Z" fill="white" />
                                                    <path d="M58.7963 227.41C55.6152 234.093 54.5183 237.485 55.3958 237.485C55.7249 237.485 56.4928 236.354 57.0412 234.915C57.9188 232.859 58.7963 232.345 61.7581 232.345C64.7198 232.345 65.7071 232.962 66.9137 235.121C67.6816 236.766 68.4494 237.485 68.7785 236.869C69.2173 235.635 62.9647 222.064 61.9775 222.064C61.6484 222.064 60.2224 224.532 58.7963 227.41ZM64.0617 228.952C64.7198 231.008 64.5004 231.317 62.0872 231.317C58.7963 231.317 58.906 231.523 60.4417 227.205C61.7581 223.709 62.5259 224.12 64.0617 228.952Z" fill="white" />
                                                    <path d="M82.8184 229.261C82.9281 233.168 83.2571 236.766 83.6959 237.075C84.1347 237.486 84.4638 236.355 84.4638 234.607C84.4638 231.831 84.9025 231.317 86.9867 231.317C88.6321 231.317 90.1679 232.448 91.4842 234.504C92.5811 236.252 93.6781 237.383 94.0072 237.075C94.3362 236.766 93.7878 235.327 92.8005 233.888C91.1551 231.626 91.1551 231.215 92.6908 230.701C94.6653 229.981 94.8847 225.046 93.0199 223.298C92.2521 222.579 89.7291 222.065 87.2061 222.065H82.8184V229.261ZM92.2521 224.738C94.3362 227.102 92.1424 229.672 87.8643 230.084C84.5735 230.392 84.4638 230.289 84.4638 226.794C84.4638 223.401 84.6832 223.093 87.6449 223.093C89.4 223.093 91.3745 223.812 92.2521 224.738Z" fill="white" />
                                                    <path d="M108.597 229.775C108.597 234.196 109.035 237.485 109.694 237.485C110.352 237.485 110.791 234.196 110.791 229.775C110.791 225.354 110.352 222.064 109.694 222.064C109.035 222.064 108.597 225.354 108.597 229.775Z" fill="white" />
                                                    <path d="M126.368 229.466L126.148 237.485L131.962 237.28L137.666 237.177L132.73 236.56L127.794 235.943L127.136 228.747L126.587 221.55L126.368 229.466Z" fill="white" />
                                                    <path d="M151.817 226.588C153.353 229.158 154.669 232.551 154.669 234.298C154.669 236.046 155.218 237.485 155.766 237.485C156.424 237.485 156.863 236.046 156.863 234.401C156.863 232.653 158.179 229.364 159.715 227.102C161.251 224.737 162.238 222.681 161.909 222.373C161.58 222.064 160.263 223.503 158.947 225.56C157.741 227.616 156.314 229.261 155.876 229.261C155.547 229.261 154.23 227.616 153.133 225.662C151.927 223.709 150.61 222.064 149.952 222.064C149.404 222.064 150.281 224.12 151.817 226.588Z" fill="white" />
                                                    <path d="M176.827 229.981L176.937 238L177.595 231.42L178.253 224.841L183.628 231.215C186.59 234.607 189.442 237.486 189.991 237.486C190.429 237.486 190.868 233.99 190.868 229.775C190.868 225.355 190.429 222.065 189.771 222.065C189.113 222.065 188.674 224.841 188.674 228.336V234.71L183.519 228.439C180.557 224.943 177.924 222.168 177.485 222.065C176.937 222.065 176.718 225.663 176.827 229.981Z" fill="white" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6" >
                                <div className="footer-right" data-black-overlay="6" >
                                    <div className="row">
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12">
                                            <div className="footer-link">
                                                {/*                                                 <h4 style={{color: "#FFFFFF"}}>Quick Link</h4> */}
                                                <ul className="ft-link">
                                                    <li><a href="/service" style={{ color: 'white' }}>Services</a></li>
                                                    <li><a href="/about" style={{ color: 'white' }}>About</a></li>
                                                    <li><a href="/contact" style={{ color: 'white' }}>Let's Talk</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                                            <div className="footer-link">
                                                <h4 style={{ color: "#FFFFFF" }}>Say Hello</h4>
                                                <ul className="ft-link" style={{ color: "#FFFFFF" }}>
                                                    <li><a href="mailto:eren@marilynpr.co" style={{ color: 'white' }}>eren@marilynpr.co</a></li>
                                                    {/*                                                     <li><a href="mailto:hr@example.com" style={{ color: 'white' }}>hr@example.com</a></li> */}
                                                </ul>
                                                {/* Social Media Section */}
                                                {/*                                                 <div className="social-share-inner" style={{color: "#FFFFFF"}}>
                                                    <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                                                        {SocialShare.map((val, i) => (
                                                            <li key={i}><a style={{ color: 'white' }} href={`${val.link}`}>{val.Social}</a></li>
                                                        ))}
                                                    </ul>
                                                </div> */}
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}

                                        <div className="col-lg-12">
                                            <div className="copyright-text">
                                                <p style={{ color: 'white' }}>Copyright © <span style={{ color: "#f42f42", fontSize: '1rem', fontWeight: '600' }}>Marilyn Pr</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}
export default Footer;