import React, { Component } from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Community Building',
        description: 'Building a community of supporters around your blockchain project, by implementing a strong community moderation, engagement activities, and marketing campaigns. The goal is to create a network of engaged users who are passionate about your project and can help spread the word to others. As we operate within 16 different local regions, we are able to locally penetrate into any community within the globe. '
    },
    {
        icon: <FiLayers />,
        title: 'Guerilla Marketing',
        description: 'Our guerilla marketing involves creating organic conversations within forums and communities on various social media such as Telegram, Reddit, and Twitter for user acquisition and brand awareness.'
    },
    {
        icon: <FiUsers />,
        title: 'Influencer Marketing',
        description: 'Involves partnering with influential individuals or organizations in the blockchain industry to promote your project. Influencers can help you reach a wider audience and build trust with potential customers, and may include bloggers, social media influencers, or industry experts. '
    },
    {
        icon: <FiMonitor />,
        title: 'Press Release',
        description: 'At Marilyn PR, we offer organic publications with backlinks, solely at cryptocurrency PR outlets. Publications are then distributed to up to 400 websites. Our blockchain PR methods and tactics are based on close connections with some of the top editors, writers, and content producers in the world. Enabling us to obtain coverage in both prestigious newspapers and Tier-1 influencers’ channels around the globe.'
    },
    {
        icon: <FiCast />,
        title: 'Advisory and Consultancy',
        description: 'Developing a strong and consistent brand identity for your blockchain project. This may include things like logo design, brand messaging, and visual branding elements that help your project stand out in a crowded market.  '
    },
    {
        icon: <FiMonitor />,
        title: 'Brand Building',
        description: 'Developing a strong and consistent brand identity for your blockchain project. This may include things like logo design, brand messaging, and visual branding elements that help your project stand out in a crowded market. '
    },
    {
        icon: <FiMonitor />,
        title: 'Social Media Management',
        description: 'Managing your social media accounts and creating content that engages and builds your audience. We assist with content creation and social media advertising to help you reach a wider audience and build brand awareness.'
    },
]
class ServiceTwo extends Component{
    render(){
        let title = 'Service'
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
{/*                                     <a href="/service-details"> */}
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
{/*                                     </a> */}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
