import React, { Component } from "react";

class BrandTwo extends Component {
    render() {
        return (
            <React.Fragment>
                <ul className="brand-style-2">
                    <li>
                        <img className='rounded-circle' src="/assets/images/brand/Arbipad.jpg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img className='rounded-circle' src="/assets/images/brand/BSCPAD.png" alt="Logo Images" />
                    </li>
                    <li>
                        <img className='rounded-circle' src="/assets/images/brand/DaoMaker.jpg" alt="Logo Images" />
                    </li>
                    <li>
                        <img className='rounded-circle' src="/assets/images/brand/PinkSale.png" alt="Logo Images" />
                    </li>
                    <li>
                        <img className='rounded-circle' src="/assets/images/brand/Polkastarter.jpg" alt="Logo Images" />
                    </li>
                    <li>
                        <img className='rounded-circle' src="/assets/images/brand/TitansVentures.jpg" alt="Logo Images" />
                    </li>
                    <li>
                        <img className='rounded-circle' src="/assets/images/brand/BNBChain.png" alt="Logo Images" />
                    </li>
                    <li>
                        <img className='rounded-circle' src="/assets/images/brand/VentFinance.jpg" alt="Logo Images" />
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;