import React from "react";
import { Link } from 'react-router-dom'

export const Logo = ({ width, height }) => (
    <div className="logo">
        <Link to="/">
            <svg width={width} height={height} viewBox={`0 0 ${width + 20} ${height + 40}`} fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 47.716V95.4316H2.24491H4.48982V51.863C4.48982 27.1308 4.71431 8.49417 4.99493 8.74399C5.38779 9.19367 12.796 21.5348 24.3012 40.9708C26.3216 44.4183 29.5206 49.8145 31.3726 52.9622C33.2808 56.1099 36.3676 61.3561 38.2757 64.5538L41.6992 70.4496H44.1686C46.3574 70.4496 46.638 70.3497 46.3574 69.5503C46.1329 69.1006 38.2196 55.6602 28.791 39.7217C19.3624 23.7832 10.2143 8.34428 8.47454 5.3964L5.33167 0.000280544H2.69389H0V47.716Z" fill="white" />
                <path d="M15.0982 7.59509C17.5676 11.7921 26.884 27.5807 35.8637 42.7198L52.0831 70.2001L54.7209 70.3499C56.7413 70.4499 57.3026 70.3499 57.1342 69.8503C56.9658 69.5006 51.1291 59.5077 44.1137 47.7162C37.1545 35.8747 27.9503 20.3359 23.685 13.1411L15.9962 0.0505145L13.3023 0.000550426H10.6084L15.0982 7.59509Z" fill="white" />
                <path d="M21.3262 0.150173C21.3262 0.300065 34.6273 22.9338 54.4386 56.4597C58.4233 63.2048 61.8468 69.1006 62.0713 69.6002C62.3519 70.3497 62.9693 70.4496 65.2703 70.3497L68.1326 70.1998L86.4286 39.372L104.669 8.49417L104.837 51.9629L104.949 95.4316H107.475H110V47.716V0.000280544H107.25H104.5L101.975 4.09733C100.628 6.39568 97.3164 11.9417 94.6787 16.4884C77.7857 45.3177 65.8316 65.2034 65.3826 65.1035C65.0458 65.0535 56.5713 51.0136 46.4692 33.8759C36.3671 16.7382 27.7242 2.1987 27.2752 1.4992C26.6017 0.499921 25.9282 0.200137 23.9078 0.100209C22.5048 0.000280544 21.3262 0.0502446 21.3262 0.150173Z" fill="white" />
                <path d="M81.2662 3.34718C73.1845 16.9374 68.3019 25.1315 62.858 34.175C56.3477 45.1171 56.1793 45.6667 58.2559 47.9651L59.3222 49.1143L64.9906 39.671C68.0774 34.4748 74.6437 23.4327 79.5825 15.0887L88.5622 -0.000415988H85.9244H83.2866L81.2662 3.34718Z" fill="white" />
                <path d="M93.0519 1.4996C92.5468 2.29903 89.9652 6.6459 87.3274 11.0927C84.6896 15.5895 77.8988 27.0812 72.1743 36.7243L61.8477 54.2117L63.0262 56.0604C63.6436 57.1096 64.3171 57.959 64.5416 57.959C64.8222 57.959 69.5926 50.0147 87.159 20.2861C96.8683 3.84791 98.7765 0.600249 98.7765 0.300464C98.7765 0.150572 97.7101 0.000680089 96.4193 0.000680089C94.3428 0.000680089 93.8938 0.200536 93.0519 1.4996Z" fill="white" />
                <path d="M98.1024 24.9823L95.9697 28.4298V61.9557V95.4316H98.2146H100.46V58.4582C100.46 38.1229 100.403 21.4848 100.347 21.4848C100.235 21.4848 99.2248 23.0837 98.1024 24.9823Z" fill="white" />
                <path d="M8.97949 58.9579V95.4316H11.505H14.0305V62.4554V29.4291L11.954 25.9816C10.7754 24.083 9.65297 22.4841 9.42847 22.4841C9.20398 22.4841 8.97949 38.8723 8.97949 58.9579Z" fill="white" />
                <path d="M88.5614 41.1207L86.4287 44.7681V70.0999V95.4316H88.6736H90.9185V66.4525C90.9185 50.514 90.8624 37.4733 90.8063 37.4733C90.694 37.4733 89.6838 39.1222 88.5614 41.1207Z" fill="white" />
                <path d="M18.5205 66.7023V95.4316H20.9338H23.2909L23.0103 69.85L22.7297 44.2185L20.8777 41.0707C19.8675 39.372 18.9695 37.973 18.8011 37.973C18.6328 37.973 18.5205 50.9137 18.5205 66.7023Z" fill="white" />
                <path d="M79.302 56.8592L77.001 60.7065L77.0571 77.9441L77.1693 95.1817L79.5826 95.3316L81.9398 95.4814V74.2467C81.9398 62.5551 81.8837 52.962 81.8275 52.962C81.7153 52.962 80.5928 54.7108 79.302 56.8592Z" fill="white" />
                <path d="M28.0615 74.5467V95.4316H30.3064H32.5513V78.194C32.5513 59.3576 32.7197 60.5068 29.184 55.3105L28.0615 53.7117V74.5467Z" fill="white" />
                <path d="M12.3477 111.62C12.3477 116.067 13.3579 116.766 13.5824 112.519L13.7507 109.671L15.0977 111.77C15.8834 112.969 16.613 113.918 16.8375 113.918C17.0058 113.918 17.7916 112.869 18.5212 111.62L19.9242 109.372L20.261 112.519L20.5977 115.667L20.6538 111.77C20.71 109.671 20.5977 107.923 20.3171 107.923C20.0926 107.923 19.3069 108.922 18.5212 110.171C17.7354 111.42 16.9497 112.419 16.7252 112.419C16.5569 112.419 15.7711 111.47 15.0416 110.321C12.965 106.973 12.3477 107.273 12.3477 111.62Z" fill="white" />
                <path d="M30.0822 110.521C28.4547 113.768 27.8934 115.417 28.3424 115.417C28.5108 115.417 28.9036 114.867 29.1843 114.168C29.6332 113.169 30.0822 112.919 31.5975 112.919C33.1128 112.919 33.618 113.219 34.2353 114.268C34.6282 115.067 35.021 115.417 35.1894 115.117C35.4139 114.518 32.2149 107.922 31.7098 107.922C31.5414 107.922 30.8118 109.122 30.0822 110.521ZM32.7761 111.27C33.1128 112.269 33.0006 112.419 31.7659 112.419C30.0822 112.419 30.1383 112.519 30.9241 110.421C31.5975 108.722 31.9904 108.922 32.7761 111.27Z" fill="white" />
                <path d="M42.373 111.42C42.4292 113.319 42.5975 115.068 42.822 115.218C43.0465 115.418 43.2149 114.868 43.2149 114.019C43.2149 112.67 43.4394 112.42 44.5057 112.42C45.3476 112.42 46.1333 112.969 46.8067 113.969C47.368 114.818 47.9292 115.368 48.0976 115.218C48.2659 115.068 47.9853 114.368 47.4802 113.669C46.6384 112.57 46.6384 112.37 47.4241 112.12C48.4343 111.77 48.5466 109.372 47.5925 108.522C47.1996 108.173 45.9088 107.923 44.618 107.923H42.373V111.42ZM47.1996 109.222C48.2659 110.371 47.1435 111.62 44.9547 111.82C43.271 111.97 43.2149 111.92 43.2149 110.221C43.2149 108.572 43.3271 108.423 44.8425 108.423C45.7404 108.423 46.7506 108.772 47.1996 109.222Z" fill="white" />
                <path d="M55.5615 111.67C55.5615 113.818 55.786 115.417 56.1228 115.417C56.4595 115.417 56.684 113.818 56.684 111.67C56.684 109.521 56.4595 107.922 56.1228 107.922C55.786 107.922 55.5615 109.521 55.5615 111.67Z" fill="white" />
                <path d="M64.6533 111.52L64.541 115.417L67.5155 115.317L70.4339 115.267L67.9084 114.967L65.3829 114.668L65.0461 111.17L64.7655 107.673L64.6533 111.52Z" fill="white" />
                <path d="M77.6738 110.121C78.4595 111.37 79.133 113.019 79.133 113.868C79.133 114.717 79.4136 115.417 79.6942 115.417C80.031 115.417 80.2555 114.717 80.2555 113.918C80.2555 113.069 80.9289 111.47 81.7147 110.371C82.5004 109.221 83.0055 108.222 82.8371 108.072C82.6687 107.922 81.9953 108.622 81.3218 109.621C80.7044 110.62 79.9748 111.42 79.7504 111.42C79.582 111.42 78.9085 110.62 78.3473 109.671C77.7299 108.722 77.0565 107.922 76.7197 107.922C76.4391 107.922 76.8881 108.922 77.6738 110.121Z" fill="white" />
                <path d="M90.4701 111.77L90.5262 115.667L90.8629 112.469L91.1997 109.272L93.9497 112.369C95.465 114.018 96.9242 115.417 97.2048 115.417C97.4293 115.417 97.6538 113.718 97.6538 111.67C97.6538 109.521 97.4293 107.923 97.0926 107.923C96.7558 107.923 96.5313 109.272 96.5313 110.97V114.068L93.8936 111.02C92.3783 109.322 91.0313 107.973 90.8068 107.923C90.5262 107.923 90.414 109.671 90.4701 111.77Z" fill="white" />
            </svg>

        </Link>
    </div>
)

